//jqueryをCDNで読み込むための対策
window.require = name => {
	if (name === 'jquery') return window.jQuery
	else throw new Error(`Cannot require ${name}`)
}
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* $$$ 外出し
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const $$$ = window.$$$ = {};

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* プラグインrequire
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
// window.AjaxZip3 = require('./_plugins/_ajaxzip3.custom')

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* require utils
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.anim = require('./_utils/_anim');
// $$$.extraChange = require('./_utils/_extraChange.js')
// $$$.extraChange.init()
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* require modules
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.ua = require('./_modules/_ua');
$$$.pcsp = require('./_modules/_pcsp');
$$$.viewport = require('./_modules/_viewport');
$$$.windowInfo = require('./_modules/_windowInfo');
$$$.resizeend = require('./_modules/_resizeend');
$$$.smoothScroll = require('./_modules/_smoothScroll');
$$$.anchorJump = require('./_modules/_anchorJump');
$$$.overlay = require('./_modules/_overlay');
// $$$.dialog = require('./_modules/_dialog');
// $$$.contentsModal = require('./_modules/_contentsModal');
// $$$.disabledToggle = require('./_modules/_disabledToggle');
// $$$.displayToggle = require('./_modules/_displayToggle');
// $$$.fileForm = require('./_modules/_fileForm');
// $$$.acc = require('./_modules/_acc');
// $$$.tab = require('./_modules/_tab');
// $$$.tree = require('./_modules/_tree');
// $$$.ajaxMore = require('./_modules/_ajaxMore');
// $$$.objFitPolyfill = require('./_modules/_objFitPolyfill');
// $$$.share = require('./_modules/_share');

// const Validator = require('./_modules/_validator/_validator');
// const ruleList = require('./_modules/_validator/_ruleList')
// const errMsgList = require('./_modules/_validator/_errorMsg/_ja')



/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* もろもろ実行
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.ua.judge();
$$$.pcsp.init(736);
$$$.viewport.init(1240, 736);
$$$.windowInfo.init();
$$$.resizeend.init();
// $$$.fileForm.init();
// $$$.validator = new Validator($('[data-validate]')[0], ruleList, errMsgList);


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window load
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on('load', function(event) {
	$$$.anchorJump.init({
		adjust: 20,
		spAdjust: 20
	});
	// $$$.objFitPolyfill.init();
	// $$$.acc.init.call($('[data-acc]'));
	// $$$.tab.init.call($('[data-tab]'));
	// $$$.tree.init.call($('[data-tree]'));
	// $$$.ajaxMore.init.call($('[data-ajax-more]'))
	// $$$.disabledToggle.init.call($('[data-disabled-target]'));
	// $$$.displayToggle.init.call($('[data-display-target]'));
});


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window resize
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on('resize', function(event) {
	$$$.pcsp.judge();
	$$$.windowInfo.updateSize();
	$$$.windowInfo.omitScrollBarSize();
});

$(window).on('resizeend.any', function(event) {
	$$$.viewport.update();
	// $$$.objFitPolyfill.update();
});

$(window).on('resizeend.width', function(event) {
});

$(window).on('resizeend.height', function(event) {
});


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window pcsp.changed
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on('pcsp.changed', function(event, mode) {
	$$$.viewport.update();
	// $$$.objFitPolyfill.update();
});



/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window scroll
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on('scroll', function(event) {
	$$$.windowInfo.updateScroll();
});



/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* click event
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/


//smoothScroll
$(document).on('click', '[href*="#"]:not([data-contents-modal])', function(event) {
	$$$.smoothScroll.scroll.call($(this), event, {
		adjust: 20,
		spAdjust: 20
	})
});

//overlay
$(document).on('click.overlay', '.overlay', function(event) {
	event.preventDefault();
	$$$.overlay.close();
});

//contents-modal
// $(document).on('click', '[data-contents-modal]', function(event) {
// 	event.preventDefault();
// 	$$$.contentsModal.open.call($(this))
// });


//fileForm ローカルファイルを選択
// $(document).on('click', '.fileForm_btn > a', function(event) {
// 	event.preventDefault();
// 	$$$.fileForm.add.call($(this));
// });

//fileForm 選択済みアイテム削除
// $(document).on('click', '.fileForm_del', function(event) {
// 	event.preventDefault();
// 	$$$.fileForm.remove.call($(this));
// });


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* other event
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
//EnterでformのSubmit防止
$(document).on('keydown', '[data-no-submit-on-enter] input', function(event) {
	if ((event.which && event.which === 13) || (event.keyCode && event.keyCode === 13)) {
		event.preventDefault();
		return false;
	}
});

//disabledToggle
// $(document).on('extraChange', '[data-disabled-target]', function(event) {
// 	event.preventDefault();
// 	$$$.disabledToggle.change.call($(this))
// });

//displayToggle
// $(document).on('extraChange', '[data-display-target]', function(event) {
// 	event.preventDefault();
// 	$$$.displayToggle.change.call($(this))
// });


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* topQuestionアコーディオン
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(".topQuestion_block").click(function(){
	$(this).children().next(".topQuestion_body").stop(true).slideToggle();
	$(this).children().children().next().toggleClass("toggle-isActive");
});


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* input type="date"過去の日付を選択させない
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(function(){
    var dtToday= new Date();
    var month= dtToday.getMonth() + 1;
    var day= dtToday.getDate();
    var year= dtToday.getFullYear();
    if(month < 10)
        month= '0' + month.toString();
    if(day < 10)
        day= '0' + day.toString();
    var minDate= year + '-' + month + '-' + day;
    $('#date').attr('min', minDate);
});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* gHeader_toggle
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(function(){
	$(".gHeader_toggle").click(function(e){
		e.preventDefault()
		$(".gNav").toggleClass("gNav-isActive");
		$(".gHeader_toggle").toggleClass("gHeader_toggle-isOpen");
		$(".gHeader_btnSp").toggleClass("gHeader_btnSp-hidden");
	});

	$('li a[href^="#"], .gNav_btn a[href^="#"]').click(function() {
		$('.gHeader_toggle').click(); // .gHeader_toggleをクリックした時と同じ処理
	});
});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* toTop / phoneBtn
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(function(){
	//ボタンの非表示
	let toTop = $('#toTop');
	toTop.hide();

	let phoneBtn = $('.phoneBtn');
	phoneBtn.hide();

	//ボタンの表示
	$(window).scroll(function(){
		if($(this).scrollTop() > 1){
			// 画面を1pxスクロールしたら、ボタンを表示する
			toTop.fadeIn(500);
			phoneBtn.fadeIn(500);
		}else{
			// 画面が1px上なら、ボタンを表示しない（画面上部）
			toTop.fadeOut(500);
			phoneBtn.fadeOut(500);
		}
	});

	// ボタンをクリックしたら、スクロールして上に戻る
	toTop.click(function(){
		$('body,html').animate({
		scrollTop: 0},500);
		return false;
	});
});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* topVoice swiper
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const swiper = new Swiper(".swiper", {
	loop: true, // ループ有効

	// ナビボタンが必要なら追加
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev"
	},
	autoHeight: true,
	allowTouchMove: false//マウスでのスワイプを禁止
});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* ヘッダー分の高さを引いてスクロール（画像遅延読み込み対応）
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(function(){
	$('a[href^="#"]').click(function() {
		const headerHeight = $(".gHeader").innerHeight();
		const href = $(this).attr("href");
		const target = $(href == "#" || href == "" ? 'html' : href);
		const position = target.offset().top - headerHeight + 20; // ここに+20をつけない場合、2回クリックすると動いてしまう
		const speedWhen = 400;
		const speedDone = 10; // 2回目のスクロールの違和感をなくすぐらいのスピード

		$.when(
			// 先に実行したい処理
			$("html, body").animate({
				scrollTop: position
			}, speedWhen, "swing"),
		).done(function() {
			// 後に実行したい処理
			const diff = target.offset().top - headerHeight + 20; // diff（差分）
			if (diff === position) {
			} else {
				$("html, body").animate({
					scrollTop: diff
				}, speedDone, "swing");
			}
		});
		return false;
	});
});
